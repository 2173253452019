const ShopCategorySortMap = {
  wear: 1,
  read: 2,
  watch: 3,
  ibooks: 4,
  other: 5,
  downlodable: 6,
};

// Replace space between title with hyphen.
// For eg: Nailed It (Martin Luther) T-shirt => nailed-it-(martin-luther)-t-shirt
const formatTitle = (title) => {
  const formattedTitle = title.replace(/\s+/g, '-');
  return formattedTitle.toLowerCase();
};

// Normalize the flatten product data into individual category.
// Ex: { watch: [], wear: [], ibooks: []}
const normalizeShopCategories = (allProducts, shouldAddFeaturedProducts = true) => {
  return allProducts.reduce((acc, value) => {
    const productDetails = value?.node;
    const categoryTags = productDetails.tags;

    const url = formatTitle(productDetails.title);

    !!categoryTags &&
      categoryTags.map((categoryName) => {
        const formattedCategoryName = categoryName.toLowerCase();
        // If the tag is downloadable, then ignore.
        if (formattedCategoryName === 'downloadable') {
          return;
        }

        if (!shouldAddFeaturedProducts && formattedCategoryName === 'featured') {
          return;
        }

        acc = {
          ...acc,
          [formattedCategoryName]: !!acc[formattedCategoryName]
            ? [
                ...acc[formattedCategoryName],
                {
                  ...productDetails,
                  url,
                  slug: formattedCategoryName,
                  tag: categoryName,
                  priority:
                    productDetails?.metafields?.find((item) => item.key === 'priority')?.value || allProducts?.length,
                },
              ]
            : [
                {
                  ...productDetails,
                  url,
                  slug: formattedCategoryName,
                  tag: categoryName,
                  priority:
                    productDetails?.metafields?.find((item) => item.key === 'priority')?.value || allProducts?.length,
                },
              ],
        };
      });

    return acc;
  }, {});
};

// Sort the shopify data on the basis of required priority.
const sortShopifyData = (normalizedProducts) => {
  const orderedCategories = Object.keys(normalizedProducts)
    .sort((x, y) => {
      return ShopCategorySortMap[x] - ShopCategorySortMap[y];
    })
    .reduce((obj, key) => {
      obj[key] = normalizedProducts[key];

      return obj;
    }, {});

  return orderedCategories;
};

module.exports = {
  normalizeShopCategories,
  sortShopifyData,
  formatTitle,
};

import React from 'react';
import renderHtml from 'htmr';
import { graphql, Link, navigate } from 'gatsby';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import { formatTitle } from '../../utils/shop';
import { getLanguageCode } from '../../utils/watch';
import { getSliceNumber } from '../../utils/podCast';
import ArrowLeft from '../../images/icon-arrow-left.svg';
import WatchItem from '../../components/Watch/WatchItem';
import { getVideoThumbnail } from '../../utils/functions';
import WatchTopItem from '../../components/Watch/WatchTopItem';
import RelatedContent from '../../components/Watch/RelatedContent';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';

const WatchItemPageComponent = (props) => {
  const {
    data: {
      bgContact,
      watchEpisodeDetails,
      languages,
      season1,
      season2,
      season3,
      relatedEpisodes,
      totalWatchData,
      readData,
      allShopifyProducts,
      podCastData,
      relatedEpisodeData,
    },
  } = props;

  const backgroundToSeasonMap = {
    'season-1': season1,
    'season-2': season2,
    'season-3': season3,
  };

  const [watchEpisodeDetail] = watchEpisodeDetails?.nodes;

  const { description, relatedArticles, relatedContent, relatedProduct, title, thumbnailImage, metaDescription } =
    watchEpisodeDetail?.data;

  const selectedSeason = watchEpisodeDetail?.data?.categories[0]?.episodeCategory?.categoryType || null;
  const bgImage = backgroundToSeasonMap[selectedSeason] || bgContact;

  const metaData = metaDescription.length ? metaDescription[0].text : description.length ? description[0]?.text : ' ';

  const seoData = {
    title: title[0].text,
    metaDesc: metaData || `Watch ${title[0].text}`,
    opengraphDescription: metaData,
    twitterDescription: metaData,
    opengraphTitle: title[0].text,
    twitterTitle: title[0].text,
    opengraphImage: {
      sourceUrl: thumbnailImage.url,
    },
    twitterImage: {
      sourceUrl: thumbnailImage.url,
    },
  };

  const languageCode = getLanguageCode(watchEpisodeDetail, languages.nodes);

  const [relatedArticle] = relatedArticles || {};

  const relatedListen = relatedContent
    ?.filter((content) => content.related_content1.type === 'podcast')
    .map((item) => {
      const pod = podCastData.nodes.find((pod) => pod.uid === item.related_content1.uid);
      return {
        thumbnailUrl: pod.data.thumnailImage.url,
        category: 'LISTEN',
        title: pod.data.podCastTitle[0].text,
        url: `/podcast/${pod.uid}`,
      };
    });

  const relatedWatch = relatedContent
    ?.filter((content) => content.related_content1.type === 'episodes')
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.related_content1.uid);
      return {
        thumbnailUrl: watch?.data?.thumbnailImage?.url || getVideoThumbnail(watch?.data?.youtubeId[0].text),
        category: 'WATCH',
        title: watch?.data?.title[0].text,
        url: `/video_episodes/${watch?.uid}`,
      };
    })
    .filter((item) => item?.thumbnailUrl);

  const relatedRead = relatedContent
    ?.filter((content) => content.related_content1.type === 'read')
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item.related_content1.uid);
      return {
        thumbnailUrl: read.data.featuredImage.url,
        category: 'READ',
        title: read.data.title.text,
        url: read.url,
      };
    });

  const relatedShop = relatedProduct
    .filter((item) => !!item.product_id)
    .map((product) => {
      const mappedProduct = allShopifyProducts?.edges?.find((mp) => mp.node.title === product.product_id?.title);
      return {
        thumbnailUrl: mappedProduct?.node?.featuredImage?.gatsbyImageData?.images?.fallback?.src,
        category: 'SHOP',
        title: product?.product_id?.title,
        url: `/product/${formatTitle(product.product_id?.title)}`,
      };
    });

  const relatedReadCount = relatedRead.length;
  const relatedEpisodesCount = relatedWatch.length;
  const relatedShopCount = relatedShop.length;
  const relatedListenCount = relatedListen.length;

  let relatedData = [];

  if (relatedWatch.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedWatch.slice(0, sliceNumber));
  }
  if (relatedRead.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedRead.slice(0, sliceNumber));
  }

  if (relatedShop?.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedShop.slice(0, sliceNumber));
  }
  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(relatedListen.slice(0, sliceNumber));
  }

  return (
    <BodyClassName className="body-light page-watch page-watch--video">
      <DefaultLayoutComponent seo={seoData}>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgImage?.childImageSharp?.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="wrapper wrapper-lg pd-x-sm la-pn">
                <div className="breadcrumb pd-t-0 bc-bg-transparent pd-x-sm watch-bc">
                  <div className="bc-holder" onClick={() => navigate(-1)}>
                    <img src={ArrowLeft} alt="" />
                    <p>Back</p>
                  </div>
                </div>
                <WatchTopItem episodeDetail={watchEpisodeDetail} languageCode={languageCode} />
              </div>
            </div>
          </BackgroundImage>

          <section className="bg-dark categories-watch--detail">
            <div className="wrapper wrapper-lg pd-x-md">
              <div className="carousel-video">
                <div className="media-content">
                  <div className="video-description">
                    {!!description.length ? renderHtml(description[0]?.text) : null}
                  </div>
                  {relatedArticle?.articles?.uid && (
                    <div className="btn-group d-flex">
                      <Link to={`/read/${relatedArticle?.articles?.uid}`} className="btn btn-lg btn-primary-ii">
                        Read Article
                      </Link>
                    </div>
                  )}
                </div>
              </div>

              {relatedData.length ? (
                <>
                  <div className="cat_watch-head cat-title pd-x-sm">Related Content</div>
                  <div className="d-flex columns-4">
                    {relatedData.map((ep) => (
                      <RelatedContent {...ep} />
                    ))}
                  </div>
                </>
              ) : (
                <div className="cat_watch-list">
                  {relatedEpisodeData.nodes.length && (
                    <>
                      <div className="cat_watch-head cat-title pd-x-sm">Related Episodes</div>
                      <div className="d-flex columns-4">
                        {relatedEpisodeData.nodes.map((ep) => (
                          <WatchItem className="cols" key={ep.id} data={ep} type="landscape" showPlayIcon />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query MyWatchItemPageQuery($id: String!, $categoryName: String!, $isBts: String) {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    season1: file(relativePath: { eq: "season-1.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    season2: file(relativePath: { eq: "season-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    season3: file(relativePath: { eq: "season-3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    watchEpisodeDetails: allPrismicEpisodes(filter: { uid: { eq: $id } }) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
              uid
            }
          }
          description {
            text
          }
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          relatedArticles: related_articles {
            articles {
              uid
            }
          }
          language
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          relatedContent: related_content {
            related_content1 {
              slug
              type
              uid
            }
          }
          relatedProduct: related_product {
            product_id {
              title
              id
              image {
                src
              }
            }
          }
          metaDescription: meta_description {
            text
          }
        }
        slugs
        uid
        url
      }
    }

    languages: allPrismicCategories(
      filter: { data: { non_english_language: { eq: "Yes" } } }
      sort: { fields: data___priority }
    ) {
      nodes {
        data {
          languageCode: language_code {
            text
          }
          title {
            text
          }
        }
        uid
      }
    }

    relatedEpisodes: allPrismicEpisodes(
      filter: {
        data: {
          language: { eq: "English" }
          categories: { elemMatch: { episodes_category: { slug: { eq: $categoryName } } } }
          bts: { eq: $isBts }
        }
        uid: { ne: $id }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 4
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
            }
          }
          description {
            text
          }
          language
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          bts
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
        uid
        url
      }
    }
    totalWatchData: allPrismicEpisodes(
      filter: { data: { language: { eq: "English" } } }
      sort: { fields: data___priority, order: ASC }
    ) {
      nodes {
        data {
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
    allShopifyProducts: allShopifyProduct(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          featuredImage {
            gatsbyImageData
          }
        }
      }
    }
    relatedEpisodeData: allPrismicEpisodes(
      filter: {
        data: {
          language: { eq: "English" }
          categories: { elemMatch: { episodes_category: { slug: { eq: $categoryName } } } }
          bts: { eq: $isBts }
        }
        uid: { ne: $id }
      }
      sort: { fields: data___priority, order: ASC }
      limit: 4
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
            }
          }
          description {
            text
          }
          language
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          bts
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
        uid
        url
      }
    }
  }
`;

export default WatchItemPageComponent;

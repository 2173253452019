import React from 'react';
import { Link } from 'gatsby';

export default function RelatedContent({ title, thumbnailUrl, category, url, categoryColor, titleColor }) {
  return (
    <div className={'cols watch_item-list'}>
      <Link className={`watch_item-figure media ratio_16-9`} to={url}>
        <div className="m_overlay" />
        <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>
      </Link>
      <span className="watch_item-title" style={{ color: categoryColor, opacity: 0.7, fontSize: 12 }}>
        {category}
      </span>
      <span className="watch_item-title" style={{ color: titleColor }}>
        {title}
      </span>
    </div>
  );
}
